// data.js
const data = [
  {"id":1,"first_name":"Bili","Jan":921,"Feb":965,"Mar":829,"Apr":578,"May":922,"June":959,"July":688,"Aug":893,"Sep":799,"Oct":717,"Nov":552,"Dec":983,"Total":3730},
  {"id":2,"first_name":"Karlen","Jan":737,"Feb":760,"Mar":948,"Apr":907,"May":902,"June":604,"July":807,"Aug":571,"Sep":712,"Oct":816,"Nov":911,"Dec":589,"Total":3692},
  {"id":3,"first_name":"Jackqueline","Jan":522,"Feb":504,"Mar":750,"Apr":654,"May":872,"June":529,"July":742,"Aug":796,"Sep":525,"Oct":981,"Nov":853,"Dec":977,"Total":3082},
  {"id":4,"first_name":"Lars","Jan":933,"Feb":527,"Mar":861,"Apr":605,"May":900,"June":781,"July":927,"Aug":689,"Sep":899,"Oct":776,"Nov":607,"Dec":584,"Total":3858},
  {"id":5,"first_name":"Teddy","Jan":963,"Feb":665,"Mar":658,"Apr":732,"May":840,"June":676,"July":648,"Aug":854,"Sep":871,"Oct":758,"Nov":562,"Dec":692,"Total":3144},
  {"id":6,"first_name":"Faunie","Jan":965,"Feb":568,"Mar":831,"Apr":654,"May":882,"June":659,"July":875,"Aug":755,"Sep":729,"Oct":996,"Nov":812,"Dec":566,"Total":4555},
  {"id":7,"first_name":"Carmine","Jan":712,"Feb":999,"Mar":738,"Apr":807,"May":855,"June":793,"July":990,"Aug":634,"Sep":851,"Oct":937,"Nov":527,"Dec":797,"Total":4276},
  {"id":8,"first_name":"Estevan","Jan":850,"Feb":803,"Mar":543,"Apr":772,"May":839,"June":907,"July":735,"Aug":617,"Sep":920,"Oct":935,"Nov":501,"Dec":697,"Total":3031},
  {"id":9,"first_name":"Lucita","Jan":694,"Feb":959,"Mar":603,"Apr":905,"May":636,"June":517,"July":599,"Aug":992,"Sep":801,"Oct":998,"Nov":719,"Dec":946,"Total":3989},
  {"id":10,"first_name":"Pincus","Jan":510,"Feb":835,"Mar":683,"Apr":881,"May":902,"June":952,"July":743,"Aug":724,"Sep":666,"Oct":522,"Nov":957,"Dec":706,"Total":4962},
  {"id":11,"first_name":"Leslie","Jan":548,"Feb":797,"Mar":557,"Apr":647,"May":904,"June":827,"July":964,"Aug":942,"Sep":614,"Oct":814,"Nov":745,"Dec":702,"Total":4670},
  {"id":12,"first_name":"Odelinda","Jan":601,"Feb":962,"Mar":550,"Apr":777,"May":857,"June":764,"July":969,"Aug":661,"Sep":634,"Oct":878,"Nov":719,"Dec":844,"Total":4770},
  {"id":13,"first_name":"Carlyle","Jan":852,"Feb":517,"Mar":964,"Apr":512,"May":675,"June":709,"July":701,"Aug":530,"Sep":577,"Oct":884,"Nov":633,"Dec":851,"Total":3159},
  {"id":14,"first_name":"Mala","Jan":859,"Feb":621,"Mar":811,"Apr":891,"May":800,"June":651,"July":620,"Aug":645,"Sep":638,"Oct":977,"Nov":686,"Dec":907,"Total":3405},
  {"id":15,"first_name":"Sonnie","Jan":804,"Feb":984,"Mar":738,"Apr":797,"May":567,"June":605,"July":888,"Aug":775,"Sep":512,"Oct":562,"Nov":669,"Dec":780,"Total":3099},
  {"id":16,"first_name":"Karlens","Jan":663,"Feb":928,"Mar":636,"Apr":688,"May":766,"June":701,"July":982,"Aug":741,"Sep":629,"Oct":647,"Nov":656,"Dec":534,"Total":4095},
  {"id":17,"first_name":"Edithe","Jan":677,"Feb":876,"Mar":603,"Apr":997,"May":534,"June":733,"July":782,"Aug":934,"Sep":969,"Oct":777,"Nov":907,"Dec":666,"Total":3598},
  {"id":18,"first_name":"Alair","Jan":846,"Feb":824,"Mar":618,"Apr":740,"May":823,"June":528,"July":952,"Aug":909,"Sep":904,"Oct":658,"Nov":846,"Dec":565,"Total":3775},
  {"id":19,"first_name":"Shandie","Jan":740,"Feb":661,"Mar":625,"Apr":577,"May":750,"June":778,"July":674,"Aug":709,"Sep":910,"Oct":999,"Nov":759,"Dec":916,"Total":3692},
  {"id":20,"first_name":"Lizabeth","Jan":830,"Feb":613,"Mar":543,"Apr":550,"May":689,"June":759,"July":918,"Aug":961,"Sep":543,"Oct":613,"Nov":941,"Dec":504,"Total":3424},
  {"id":21,"first_name":"Idelle","Jan":847,"Feb":587,"Mar":513,"Apr":673,"May":773,"June":705,"July":628,"Aug":570,"Sep":786,"Oct":870,"Nov":840,"Dec":612,"Total":3326},
  {"id":22,"first_name":"Brietta","Jan":930,"Feb":960,"Mar":692,"Apr":661,"May":758,"June":906,"July":710,"Aug":842,"Sep":727,"Oct":527,"Nov":985,"Dec":509,"Total":4316},
  {"id":23,"first_name":"Borden","Jan":872,"Feb":696,"Mar":741,"Apr":519,"May":715,"June":747,"July":882,"Aug":959,"Sep":869,"Oct":699,"Nov":857,"Dec":573,"Total":4591},
  {"id":24,"first_name":"Catlin","Jan":835,"Feb":877,"Mar":902,"Apr":552,"May":985,"June":548,"July":769,"Aug":620,"Sep":561,"Oct":542,"Nov":546,"Dec":633,"Total":3504},
  {"id":25,"first_name":"Hughie","Jan":612,"Feb":962,"Mar":708,"Apr":908,"May":645,"June":970,"July":552,"Aug":768,"Sep":884,"Oct":543,"Nov":554,"Dec":551,"Total":3336},
  {"id":26,"first_name":"Ganny","Jan":862,"Feb":551,"Mar":775,"Apr":939,"May":671,"June":645,"July":653,"Aug":997,"Sep":858,"Oct":616,"Nov":778,"Dec":542,"Total":3851},
  {"id":27,"first_name":"Corena","Jan":927,"Feb":953,"Mar":910,"Apr":896,"May":621,"June":575,"July":666,"Aug":733,"Sep":829,"Oct":727,"Nov":544,"Dec":758,"Total":4400},
  {"id":28,"first_name":"Tobe","Jan":521,"Feb":563,"Mar":776,"Apr":519,"May":519,"June":636,"July":937,"Aug":714,"Sep":777,"Oct":790,"Nov":500,"Dec":799,"Total":3159},
  {"id":29,"first_name":"Philippine","Jan":704,"Feb":672,"Mar":836,"Apr":514,"May":555,"June":719,"July":689,"Aug":614,"Sep":538,"Oct":779,"Nov":859,"Dec":672,"Total":4369},
  {"id":30,"first_name":"Eugene","Jan":964,"Feb":793,"Mar":997,"Apr":744,"May":672,"June":963,"July":655,"Aug":760,"Sep":748,"Oct":990,"Nov":916,"Dec":684,"Total":4218},
  {"id":31,"first_name":"Esme","Jan":779,"Feb":970,"Mar":786,"Apr":633,"May":748,"June":893,"July":593,"Aug":653,"Sep":837,"Oct":893,"Nov":855,"Dec":685,"Total":4835},
  {"id":32,"first_name":"Adam","Jan":546,"Feb":747,"Mar":980,"Apr":833,"May":809,"June":897,"July":516,"Aug":627,"Sep":611,"Oct":543,"Nov":795,"Dec":555,"Total":4683},
  {"id":33,"first_name":"Alastair","Jan":839,"Feb":595,"Mar":813,"Apr":578,"May":524,"June":669,"July":981,"Aug":737,"Sep":928,"Oct":803,"Nov":952,"Dec":704,"Total":3919},
  {"id":34,"first_name":"Zarah","Jan":939,"Feb":828,"Mar":764,"Apr":692,"May":574,"June":536,"July":860,"Aug":734,"Sep":636,"Oct":518,"Nov":620,"Dec":612,"Total":4482},
  {"id":35,"first_name":"Peri","Jan":720,"Feb":607,"Mar":811,"Apr":995,"May":916,"June":768,"July":971,"Aug":866,"Sep":935,"Oct":531,"Nov":748,"Dec":979,"Total":4594},
  {"id":36,"first_name":"Beale","Jan":907,"Feb":787,"Mar":816,"Apr":922,"May":585,"June":892,"July":780,"Aug":729,"Sep":992,"Oct":517,"Nov":955,"Dec":876,"Total":3060},
  {"id":37,"first_name":"Nicolais","Jan":576,"Feb":783,"Mar":738,"Apr":854,"May":817,"June":704,"July":841,"Aug":542,"Sep":742,"Oct":576,"Nov":674,"Dec":650,"Total":3400},
  {"id":38,"first_name":"Thalia","Jan":791,"Feb":858,"Mar":911,"Apr":593,"May":615,"June":863,"July":752,"Aug":587,"Sep":515,"Oct":916,"Nov":840,"Dec":594,"Total":3359},
  {"id":39,"first_name":"Luelle","Jan":931,"Feb":546,"Mar":742,"Apr":898,"May":829,"June":623,"July":791,"Aug":657,"Sep":504,"Oct":526,"Nov":519,"Dec":703,"Total":3778},
  {"id":40,"first_name":"Siobhan","Jan":704,"Feb":556,"Mar":521,"Apr":742,"May":680,"June":768,"July":889,"Aug":957,"Sep":590,"Oct":681,"Nov":898,"Dec":888,"Total":3351},
  {"id":41,"first_name":"Kerstin","Jan":719,"Feb":558,"Mar":971,"Apr":835,"May":683,"June":555,"July":789,"Aug":785,"Sep":924,"Oct":983,"Nov":998,"Dec":959,"Total":3672},
  {"id":42,"first_name":"Karrie","Jan":795,"Feb":993,"Mar":768,"Apr":848,"May":757,"June":940,"July":652,"Aug":879,"Sep":792,"Oct":532,"Nov":596,"Dec":656,"Total":4060},
  {"id":43,"first_name":"Babs","Jan":563,"Feb":694,"Mar":531,"Apr":667,"May":823,"June":981,"July":905,"Aug":511,"Sep":657,"Oct":801,"Nov":704,"Dec":610,"Total":4385},
  {"id":44,"first_name":"Antonia","Jan":815,"Feb":936,"Mar":894,"Apr":986,"May":502,"June":751,"July":956,"Aug":844,"Sep":897,"Oct":893,"Nov":713,"Dec":899,"Total":4774},
  {"id":45,"first_name":"Cathe","Jan":918,"Feb":889,"Mar":767,"Apr":660,"May":609,"June":848,"July":622,"Aug":809,"Sep":564,"Oct":956,"Nov":630,"Dec":620,"Total":3603},
  {"id":46,"first_name":"Milo","Jan":957,"Feb":841,"Mar":906,"Apr":904,"May":517,"June":765,"July":730,"Aug":878,"Sep":825,"Oct":524,"Nov":571,"Dec":767,"Total":3032},
  {"id":47,"first_name":"King","Jan":547,"Feb":616,"Mar":686,"Apr":624,"May":951,"June":568,"July":774,"Aug":943,"Sep":525,"Oct":888,"Nov":551,"Dec":579,"Total":4302},
  {"id":48,"first_name":"Flynn","Jan":580,"Feb":719,"Mar":630,"Apr":977,"May":820,"June":511,"July":669,"Aug":634,"Sep":645,"Oct":964,"Nov":504,"Dec":763,"Total":4294},
  {"id":49,"first_name":"Zollie","Jan":920,"Feb":943,"Mar":613,"Apr":762,"May":986,"June":704,"July":628,"Aug":831,"Sep":923,"Oct":977,"Nov":853,"Dec":677,"Total":3673},
  {"id":50,"first_name":"Edd","Jan":664,"Feb":633,"Mar":811,"Apr":898,"May":594,"June":586,"July":818,"Aug":817,"Sep":628,"Oct":801,"Nov":701,"Dec":636,"Total":3548}
  ];
  
  export default data;
  